import { styled } from '@mui/material';

// based on the default theme.mixins.toolbar we'd been using
// https://github.com/mui/material-ui/blob/9c472c1e06b63b67ee4ce31eb96e5ca14c6af2a4/packages/mui-material/src/styles/createMixins.js#L3-L13
export const ToolbarOffset = styled('div')(({ theme }) => ({
  minHeight: 56,
  marginTop: 16, // LIFT-350
  [theme.breakpoints.up('sm')]: {
    marginTop: 24, // LIFT-350
    minHeight: 64,
  },
}));
